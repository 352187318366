<template>
    <header class="navbar-container" :class="{ 'scrolled': scrolled }">
        <div class="inner">
            <b-navbar toggleable="lg" class="p-0" id="navbar">
                <b-navbar-brand hreflang="en-ca" href="/" class="brand">
                    <img :src="$image('logo-default.svg')" alt="Peekage Logo" width="129" height="72">
                </b-navbar-brand>

                <b-navbar-toggle ref="navbar-toggle" target="nav-collapse" class="toggler outline-none">
                    <template #default="{ expanded }">
                        <span v-if="expanded" class="icon close size md" />
                        <span v-else class="icon menu size md" />
                    </template>
                </b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav class="nav-menus" @hide="handleHide" @shown="handleShown" @hidden="handleHidden" style="background-color: white;">
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="navbar-desktop" align="center">
                        <template v-for="(menu, i) in menus">
                            <component :is="menu.component" :key="i" :menu="menu" @handleCollapse="handleCollapse" />
                        </template>
                    </b-navbar-nav>
                </b-collapse>

                <div class="only-desktop header-talk-to-expert p-y-16">
                    <nuxt-link hreflang="en-ca" to="/contact?talk-to-an-expert=true" style="letter-spacing: -0.3px;" class="p-x-20 d-flex btn sm primary font f-s-14 l-h18 f-w-700">
                        Talk to an expert
                    </nuxt-link>
                </div>
            </b-navbar>
        </div>
    </header>
</template>

<script>
import PeekageNavGroup from '~/components/layout/header/peekage-nav-item-group';
import PeekageNavItem from '~/components/layout/header/peekage-nav-item';
import TalkToExpert from '~/components/layout/header/talk-to-exp';

const menus = [
    // {
    //     name: 'Why Peekage ?',
    //     component: 'PeekageNavItem',
    //     uri: '/#WhyPeekage',
    //     anchored: true,
    // },
    {
        name: 'Platform',
        component: 'PeekageNavGroup',
        version: 'Style1',
        sub: [
            {
                title: 'Consumer Insight',
                subTitle: 'Peekage empowers brands to integrate actionable insights across their business with its automated product and concept testing solutions.',
                imgPath: 'header/consumerInsight.png',
                path: '/consumer-insights-company',
                type: 'NAV_ITEM',
            },
            {
                title: 'Shopper Marketing',
                subTitle: "Peekage's digital product sampling programs let you engage qualified shoppers through targeted in-home trials. Use the Audience Builder to create specific segments, matching your products to shoppers for reviews, awareness, and sales.",
                path: '/product-sampling-program',
                imgPath: 'header/shopperMarketing.png',
                type: 'NAV_ITEM',
            },
            {
                title: 'Why Peekage?',
                subTitle: 'Access a diverse community of 5M+ shoppers and drive retail velocity with geo-targeting. Get live consumer data in days, not weeks, with affordable, all-in-one product testing solutions that are easy to use, requiring no data science expertise.',
                imgPath: 'header/shopperMarketing.png',
                type: 'SIDE_ITEM',
            },
        ],
    },
    {
        name: 'Solutions',
        component: 'PeekageNavGroup',
        version: 'Style2',
        sub: [
            {
                name: 'Consumer Insights',
                subtitle: 'Consumer Insight Use Cases',
                identifier: 'consumer-insights-company',
                useCases: [
                    {
                        case: 'ConceptTesting',
                        uri: '#',
                    },
                    {
                        case: 'Taste & Formula Testing',
                        uri: '#',
                    },
                    {
                        case: 'Buyer Personas & Segmentation',
                        uri: '#',
                    },
                    {
                        case: 'In-home Usage Testing (IHUT)',
                        uri: '#',
                    },
                    {
                        case: 'Efficacy & Claims Testing',
                        uri: '#',
                    },
                    {
                        case: 'Packaging Testing',
                        uri: '#',
                    },
                    {
                        case: 'Pricing',
                        uri: '#',
                    },
                    {
                        case: 'Messaging & Positioning',
                        uri: '#',
                    },
                    {
                        case: 'Competitive Analysis',
                        uri: '#',
                    },
                    {
                        case: 'Brand Perception',
                        uri: '#',
                    },
                    {
                        case: 'In-Store Experience',
                        uri: '#',
                    },
                    {
                        case: 'Voice of the Customer (VoC)',
                        uri: '#',
                    },
                    {
                        case: 'Usage and Attitude (U&A)',
                        uri: '#',
                    },
                ],
                uri: '/consumer-insights-company',
                anchored: false,
            },
            {
                name: 'Digital Product Sampling',
                subtitle: 'Digital Product Sampling Use Cases',
                identifier: 'product-sampling-program',
                useCases: [
                    {
                        case: 'At-home Product Sampling',
                        uri: '#',
                    },
                    {
                        case: 'Increase Retail Velocity with Cashback',
                        uri: '#',
                    },
                    {
                        case: 'Ratings & Reviews',
                        uri: '#',
                    },
                ],
                uri: '/product-sampling-program',
                anchored: false,
            },
            {
                name: 'By Industries',
                subtitle: 'Industries Use Cases',
                identifier: 'industries',
                useCases: [
                    {
                        case: 'Food & Beverage',
                        uri: '#',
                    },
                    {
                        case: 'Pet Products',
                        uri: '#',
                    },
                    {
                        case: 'Household Products',
                        uri: '#',
                    },
                    {
                        case: 'Personal Care & Beauty',
                        uri: '#',
                    },
                    {
                        case: 'Health & Wellness',
                        uri: '#',
                    },
                ],
                uri: '/product-sampling-program',
                anchored: false,
            },
        ],
    },
    {
        name: 'Consumer App',
        component: 'PeekageNavItem',
        uri: '/consumer-apps',
        anchored: false,
    },
    {
        name: 'Resources',
        version: 'Style3',
        component: 'PeekageNavGroup',
        sub: [
            {
                name: 'Blog',
                subtitle: '',
                uri: 'https://peekage.com/blog',
                anchored: false,
            },
            {
                name: 'FAQ',
                subtitle: '',
                uri: '/faq',
                anchored: false,
            },
            {
                name: 'Case Studies',
                subtitle: '',
                uri: '/case-studies',
                anchored: false,
            },
            {
                name: 'Our Community',
                subtitle: '',
                uri: '/natural-shoppers-community',
                anchored: false,
            },
        ],
    },
    {
        name: 'Need help?',
        component: 'TalkToExpert',
        uri: '/contact?talk-to-an-expert=true',
        anchored: false,
        onlyDevice: true,
    },
];
export default {
    components: {
        PeekageNavGroup,
        PeekageNavItem,
        TalkToExpert,
    },
    data() {
        return {
            menus: [...menus],
            scrolled: false,
            backdrop: false,
            visible: true,

        };
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleHeaderStyles);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleHeaderStyles);
        window.removeEventListener('click', this.handleHide);
    },
    mounted() {
        this.handleHeaderStyles();
        document.getElementById('__layout').addEventListener('click', this.handleHide);
    },
    methods: {
        handleCollapse() {
            if (window.innerWidth > 992) {
                return;
            }
            document.body.style.overflow = 'scroll';
            this.$root.$emit('bv::toggle::collapse', 'nav-collapse');
        },
        handleHide() {
            window.document.querySelectorAll('.peekage-nav-item').forEach((item) => {
                // if (item.id !== this.menu.name) {
                item.classList.remove('d-none');
                // }
            });
        },
        handleHeaderStyles () {
            if (process.client) {
                this.scrolled = window.scrollY > 0;
            } else {
                this.scrolled = true;
            }
        },
        handleShown() {
            document.body.style.overflow = 'hidden';
        },
        handleHidden() {
            document.body.style.overflow = 'scroll';
        },
    },
};
</script>

<style lang="scss" >
    .header-only-logo {
        .header-talk-to-expert, .nav-menus {
            display: none !important;
            border-bottom: 2px solid #e5e5e5;
            margin-right: 0;
        }
        @media (min-width: 992px){
            margin-right: 28px;
        }
    }
</style>

<style lang="scss" scoped>

    .navbar-container {
        .inner{
            padding: 0 0;
            max-width: 1164px;
            margin: 0 auto;
            .brand{
                margin-left: 20px;
                padding: 0;
            }
            .toggler{
                margin-right: 20px;
            }
        }
        #nav-collapse{
            border-top:2px solid #E5E5E5;
            background: var(--Color-White, #FFF);
            box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.12);
        }
        border-bottom:2px solid #E5E5E5;
        .outline-none{
            outline: none;
        }
        // background-color: #fff;
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 1000;
        background-color: #fff;
        &.scrolled {
            box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
        }
        .navbar-toggler {
            border: unset;
        }

        @media (min-width: 992px) {

            .inner{
                    padding: 0 28px;
                    .brand{
                        margin-left: 0;
                    }
                    .toggler{
                        margin-right: 0;
                    }
                }

                border-bottom: none;
                #nav-collapse{
                    box-shadow: unset;
                    border-top: unset;
                }

            border-bottom: unset;
            position: sticky;
            .navbar-desktop {
                margin-left: 40px;
                gap: 16px;
            }
        }
        @media (min-width: 1164px) {
            .inner{
                padding: 0 0;
                .brand{
                    margin-left: 0;
                }
                .toggler{
                    margin-right: 0;
                }
            }
        }
    }
</style>
